<template>
  <v-container fluid>
    <v-row
      class="led-details d-inlineflex"
      style="margin-top: -12px"
    >
      <v-col
        v-if="loading"
        cols="12"
        class="mb-0 pa-0"
      >
        <v-progress-linear
          indeterminate
          absolute
        />
      </v-col>

      <!-- The raw details display -->
      <v-col
        cols="12"
        class="mt-4"
      >
        <div class="led_details">
          <template v-for="detail in detailsToShow">
            <div
              :key="'key_' + detail"
              :class="'led_' + detail"
            >
              {{ ledDetails[detail] }}
            </div>
          </template>
        </div>
      </v-col>

      <!-- The note list -->
      <v-col
        cols="12"
        lg="6"
        class="pr-2"
      >
        <ItemList
          :loading="loading"
          class="note_list_led"
          title="Notes"
          :list-items="ledNotes"
          action-icon="mdi-plus"
          action-text="New Note"
          sticky-icons
          chip-images
          @itemClicked="openNoteDetails"
          @actionClicked="openNoteForm"
        />
      </v-col>

      <!-- The experiment list -->
      <v-col
        v-if="ledExps.length"
        cols="12"
        lg="6"
        class="pl-2"
      >
        <ItemList
          :loading="loading"
          class="exp_list_led"
          title="Experiments"
          :list-items="ledExps"
          sticky-icons
          chip-images
          sticky-on-icon="mdi-clipboard-alert"
          sticky-off-icon="mdi-clipboard-check"
          @itemClicked="openLEDDetails"
        />
      </v-col>

      <!-- The LED detail dialog -->

      <LEDDetailDialog
        :dialog-open="ledDetailOpen"
        :data="ledDetailData"
        :custom="ledCustomData"
        :led="ledDetails"
        :loading="loading"
        @closeClicked="closeLEDDetails"
        @refreshClicked="refreshDetails"
      />

      <!-- The add new note dialog -->
      <v-dialog
        v-model="noteFormOpen"
        scrollable
      >
        <v-card
          tile
          class="add-note"
        >
          <!-- The dialog toolbar -->
          <v-toolbar
            card
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click.native.stop="closeNoteForm"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add New Note</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                dark
                flat
                @click.stop="clearNoteForm"
              >
                <span>Clear</span>
              </v-btn>
              <v-btn
                dark
                flat
                :loading="loading"
                @click.native="refreshNoteForm"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!-- The note form itself -->
          <v-card-text>
            <v-row>
              <v-col
                md="6"
                offset-md="3"
                sm="8"
                offset-sm="2"
                cols="12"
              >
                <v-form
                  ref="addNoteForm"
                  v-model="noteFormValid"
                  lazy-validation
                >
                  <template v-for="field in newNoteFormFields">
                    <FormField
                      :key="field.key"
                      :ref="field.type"
                      :entry="field"
                      @onDynamicUpdate="refreshNoteForm"
                    />
                  </template>

                  <UserSelectSubmitter
                    :form-valid="noteFormValid"
                    :loading="loading"
                    :show-clear="false"
                    @onSubmitClick="postNoteForm"
                    @onClearClick="clearNoteForm"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- This is the led note detail -->
      <NoteDetailDialog
        :dialog-open="noteDetailOpen"
        :note="ledNotes[noteDetailIndex]"
        :loading="loading"
        @closeClicked="closeNoteDetails"
        @refreshClicked="refreshInfo"
      />
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import moment from 'moment'

export default {
  name: 'LEDDetail',
  components: {
    FormField: () => import('./FormField'),
    UserSelectSubmitter: () => import('./UserSelectSubmitter'),
    ItemList: () => import('./ItemList'),
    NoteDetailDialog: () => import('./NoteDetailDialog'),
    LEDDetailDialog: () => import('./LEDDetailDialog')
  },
  data () {
    return {
      detailsToShow: ['internal_serialnumber', 'manufacturer', 'lot', 'manufacturer_serialnumber', 'username', 'time_string'],
      ledDetails: {},
      ledNotes: [],
      ledExps: [],
      avatarList: [],
      loading: false,
      loggedIn: true,
      newNoteFormFields: [],
      ledDetailOpen: false,
      noteFormOpen: false,
      noteFormValid: false,
      noteDetailOpen: false,
      noteDetailIndex: 0,
      LEDexpID: 0,
      ledDetailData: {},
      ledCustomData: []
    }
  },
  watch: {
    LEDexpID: function () {
      this.refreshDetails()
    }
  },
  mounted () {
    // Subscribe to the global refresh event
    EventBus.$on('refresh', this.refreshInfo)
    // Refresh the LED info on load of the component
    this.refreshInfo()
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshInfo)
  },

  methods: {
    reset: function () {
      this.refreshInfo()
    },
    refreshInfo: function () {
      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.getLEDDetail([
          { key: 'led_id', value: this.$route.params.led_id }, // Fetch the details for the LED in the URL
          { key: 'action', value: 'refresh' }
        ], resp => {
          this.loadLEDInfo(resp)

          this.loading = false
        })
      }
    },
    refreshDetails: function () {
      console.log('refresh', this.LEDexpID)

      this.loading = true

      this.ledDetailData = {}
      this.ledCustomData = []

      this.$root.$data.si.getLEDExpDetail([
        { key: 'led_id', value: this.$route.params.led_id },
        { key: 'exp_id', value: this.LEDexpID }
      ], (response) => {
        console.log('Experiment ID:', this.LEDexpID)
        console.log('Details response from LED/Exp request:', response.experiments[0])

        this.ledDetailData = response.experiments[0]

        this.$root.$data.si.getCustom([
          { key: 'led_id', value: this.$route.params.led_id },
          { key: 'exp_id', value: this.LEDexpID }

        ], resp => {
          this.ledCustomData = resp.customElements
          console.log('Custom', this.ledCustomData)
          this.loading = false
        })

        this.loading = false
      })
    },
    loadLEDInfo: function (ledInfoResponse) {
      this.ledDetails = ledInfoResponse.led[0]

      const rightNow = moment()
      this.ledDetails.time_string = moment.unix(this.ledDetails.timestamp).calendar(rightNow)

      // console.log(this.ledDetails)
      // Add/refresh the notes array
      this.ledNotes = ledInfoResponse.notes
      this.ledExps = ledInfoResponse.experiments
      // Load all the user avatars
      // TODO: Make into a global list
      this.$root.$data.si.getUserData(respData => {
        this.avatarList = []

        respData.members.forEach(member => {
          this.avatarList[member.user_id] = this.$root.$data.formAvatarURL(member.profile_picture)
        })
      })
    },
    openNoteDetails: function (noteId) {
      // Find and store the index of the note with this id (indexed such that a refresh will update the data).
      this.ledNotes.forEach((note, index) => {
        if (Number(note.id) === noteId) {
          this.noteDetailIndex = index
        }
      })
      // Open the detail page
      this.noteDetailOpen = true
    },
    closeNoteDetails: function () {
      this.noteDetailOpen = false
    },
    openLEDDetails: function (expId) {
      this.LEDexpID = expId

      this.ledDetailOpen = true
    },
    closeLEDDetails: function () {
      setTimeout(() => {
        this.refreshInfo()
      }, 250)
      this.ledDetailOpen = false
    },
    openNoteForm: function () {
      this.refreshNoteForm()
      this.noteFormOpen = true
    },
    closeNoteForm: function () {
      // Refresh the note list
      setTimeout(() => {
        this.refreshInfo()
      }, 250)

      this.ledDetailOpen = false
      this.clearNoteForm()
    },
    refreshNoteForm: function () {
      if (!this.loading) {
        this.loading = true

        // Make sure the form knows which LED it is for
        this.newNoteFormFields.push({ key: 'led_id', value: this.$route.params.led_id })
        // Only request a refresh
        this.newNoteFormFields.push({ key: 'action', value: 'refresh' })

        this.$root.$data.si.postLEDNoteForm(this.newNoteFormFields, respData => {
          this.newNoteFormFields = respData.fields

          this.loading = false
        })
      }
    },
    postNoteForm: function (chosenUser) {
      if (!this.loading) {
        this.loading = true

        if (this.$refs.addNoteForm.validate()) {
          // Make sure the form knows which LED it is for
          this.newNoteFormFields.push({ key: 'led_id', value: this.$route.params.led_id })
          // Add the chosen user, if it is valid
          if (chosenUser != null) {
            this.newNoteFormFields.push({ key: 'chosen_user', value: chosenUser.id })
          }
          // Post with the action set to save (srs business)
          this.newNoteFormFields.push({ key: 'action', value: 'save' })

          // Now post
          this.$root.$data.si.postLEDNoteForm(this.newNoteFormFields, respData => {
            console.log('Posted with save action to database, received the following response:')
            console.log(respData)

            // Check to see if the server added the LED to the database
            if (respData.action === 'saved') {
              // // Trigger an upload of the single file dropzones
              // let filesToUpload = 0
              // this.$refs.upload_single.forEach(dz => {
              //   if (dz.hasFiles()) {
              //     // Keep count of how many files need uploading
              //     filesToUpload++
              //     // Set the note_id param to the one assigned by the server
              //     this.dropzoneOptions.params.note_id = respData.note_id
              //     // Trigger the upload
              //     dz.dropzoneUpload()
              //     console.log('Upload triggered')
              //   }
              // })
              // // Now form the success message
              // let successMessage = 'Successfully added note with id: ' + respData.note_id
              // if (filesToUpload > 0) {
              //   // There are still files to upload, so let them know that is happening as well
              //   successMessage += '. Uploading note files now.'
              // } else {
              //   // If there were no files to upload, then there is not going to be an upload success...
              //   this.closeNoteForm()
              // }
              // The add note post was successful, close the form.
              this.closeNoteForm()
              // Show the snackbar via the event bus
              EventBus.$emit('info', 'Successfully added note with id: ' + respData.note_id)
            } else {
              // If not, refresh the form with new data
              this.newNoteFormFields = respData.fields
              // Then show the user the error message
              EventBus.$emit('error', {
                title: 'Note not posted',
                message: respData.error_message != null ? respData.error_message : 'Unknown error'
              })
            }

            this.loading = false
          })
        } else {
          EventBus.$emit('info', 'Form invalid, will not post for save')
          console.log('Form invalid, will not post for save')
          this.loading = false
        }
      } else {
        console.log('Still loading, please wait')
      }
    },
    clearNoteForm: function () {
      // Reset the basic form fields
      this.$refs.addNoteForm.reset()
      // Reset all the dropzones in the form
      // this.$refs.upload_single.forEach(dz => {
      //   dz.resetDropzone()
      // })
      // Refill the form with data from the server, on a timeout, and if the note form is open
      if (this.noteFormOpen) {
        setTimeout(() => {
          this.refreshNoteForm()
        }, 250)
      }
    }
  }
}
</script>

<style scoped>

</style>
